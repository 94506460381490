import { OKTA_CALLBACK_PATH } from '../../Constants';

let config = {};
if(window.location.href.indexOf("dev-mps-looker") !== -1 ||
    window.location.href.indexOf("dev-reporting") !== -1 ||
    window.location.href.indexOf("localhost") !== -1 ) {
    config = {
      issuer: 'https://rxservices.oktapreview.com/oauth2/aus8soo805RV7AQHk1d7',
      clientId: '0oa3v95k6wAwVKUEt1d7',
      redirectUri: window.location.origin + OKTA_CALLBACK_PATH,
      postLogoutRedirectUri: window.location.origin,
      pkce: true,
    };
} else if(window.location.href.indexOf("uat-mps-looker") !== -1 ||
    window.location.href.indexOf("uat-reporting") !== -1 || 
    window.location.href.indexOf("staging-mps-looker") !== -1 ||
    window.location.href.indexOf("staging-reporting") !== -1 ) {
    config = {
      issuer: 'https://rxservices-uat.okta.com/oauth2/aus1g8s2e1fESPZAC4h7', 
      clientId: '0oa1guannqWRuMYz64h7',
      redirectUri: window.location.origin + OKTA_CALLBACK_PATH,
      postLogoutRedirectUri: window.location.origin,
      pkce: true,
    };
} else {
    config = {
      issuer: 'https://rxservices.okta.com/oauth2/aus1cdik9i6MtT5224h7',
      clientId: '0oa1mh6x8n2pAT1cu4h7',
      redirectUri: window.location.origin + OKTA_CALLBACK_PATH,
      postLogoutRedirectUri: window.location.origin,
      pkce: true,
    };
}

const GeneralOktaAuth = config;

export default GeneralOktaAuth;