import { Button, Divider, List, Modal, notification, Typography } from "antd";
import React from "react";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import { API } from "../../services/LookerAPI/LookerAPI";
import { IHomePage } from "../../services/SettingsManagement/HomePageModels";
import Looker from "../Looker/Looker";
import "../Looker/Looker.scss";
import "./HomePage.scss";



interface IProps {
}

const HomePage: React.FC<IProps> = () => {

  const { Text } = Typography;

  const [homePageInfo, setHomePageInfo] = React.useState<IHomePage>(null);
  const [homePages, setHomepages] = React.useState<IHomePage[]>(null);
  const [multipleHomepages, setMultipleHomepages] = React.useState<boolean>(false);

  const setUserHomePage = (homepage: IHomePage) => {
    const showResult = (result: any) => {
      openNotification("Success!", "Your Home Page was successfully updated.", "success");
    }

    const showError = (error: Error) => {
      console.log(error);
      openNotification("Error!", "There was a problem updating your Home Page.", "error");
    }

    API.setUserHomePage(homepage.type, homepage.looker_id, homepage.embed_url, showResult, showError);
    setHomePageInfo(homepage);
    setMultipleHomepages(false);
}

  const openNotification = (message: string, description: string, type: string) => {
    notification[type]({
      message,
      description,
      placement: "topRight",
      duration: 3
    });
  };

  React.useEffect(() => {

    document.title = 'Home';

    const setDefaultHomePageResult = (result: any) =>{
        setHomePageInfo(result);
    }

    const setDefaultHomePageError = (error: Error) =>{
      setHomePageInfo({
        type: "dashboard",
        looker_id: "XrzCG4zAZSlBk9OSQ3l2Ja",
        embed_url: "/dashboards/XrzCG4zAZSlBk9OSQ3l2Ja",
        title: "EnterpriseRx Data Insights™: At A Glance"
      })
      //openNotification("Error", `There was a problem loading your Home Page, displaying "EnterpriseRx Data Insights™: At A Glance" instead.`, "error");
    }

    const setResult = (result: any) =>{
      if (Array.isArray(result)) {
        if (result.length > 1) {
          setHomepages(result);
          setMultipleHomepages(true);
        } else {
          setHomePageInfo(result[0]);
        }
      } else {
        setHomePageInfo(result);
      }
    }
    const setError = (error: Error) =>{
      API.getDefaultHomePage(setDefaultHomePageResult, setDefaultHomePageError);
    }
    API.getHomePage(setResult,setError);
  }, []);

  React.useEffect(() => {
    if (homePageInfo) {
        document.title = homePageInfo.title;
    }
  }, [homePageInfo]);

  return (
    <>
      {
      multipleHomepages &&
      <Modal
      closable={false}
      title={"Choose A Home Page"}
      visible={true}
      width={550}
      footer={
        <></>
      }>
        <Text className="group-homepage-modal-header">
          You were assigned multiple Home Pages, please choose one
        </Text>
        <Divider style={{ marginBottom: 0, marginTop: 10 }} />
          <div className="multi-groups-list">
          <List style={{width: "97%"}}>
            {homePages.map((val, index) => {
              return (
              <List.Item key={index}>
                  <Text>{val.title}</Text>
                  <Button  className="multi-groups-btn" onClick={() => setUserHomePage(val)}>Select</Button>
              </List.Item>
              )
            })}
          </List>
          </div>
      </Modal>
      }
      <div className="full-height-minus-header">
          {homePageInfo ? (
            <Looker embeddedPath={homePageInfo.embed_url}></Looker>
          ) : (
            <CustomSpinner />
          )}
      </div>
    </>
  );
};

export default HomePage